import React from 'react';

const App = () => {
  return (
    <div className='h-[100vh] w-full flex flex-col justify-center items-center'>
      <div className='w-96 text-center text-2xl'>
        BULLAPP.CLOUD SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ
      </div>
      <div className='mt-4 text-center text-sm'>
        ul. Hoża 86 lok. 410
        <br />
        00-682 Warszawa
      </div>
      <div className='mt-4 text-center text-sm'>
        NIP: 7011202892
        <br />
        REGON: 528485891
        <br />
        KRS: 0001103080
      </div>
    </div>
  );
};

export default App;
